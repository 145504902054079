<template>
   <div class="container-checkbox-sleep">
      <div class="checkBox-content">
         <div class="img-box">
            <img src="../assets/img/checkInBoxImg.svg" alt="" />
         </div>
         <div class="title-checkbox">
            <p>Check your inbox</p>
         </div>
         <div class="text-checkbox">
            <p>We sent you an activation link. Please be sure to check your spam folder too.</p>
         </div>
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.container-checkbox-sleep
   width: 100%
   height: 100%
   margin: 0 auto
   max-width: 1246px
   padding: 40px 20px
   display: flex
   text-align: center
   align-items: center
   justify-content: center
   flex-direction: column
   @media screen and (max-width:380px)
       padding: 15px 20px
.checkBox-content
    max-width: 356px
    width: fit-content
.img-box
    margin: 0 auto
    max-width: 113.96px
.title-checkbox
    margin-top: 33.12px
    font-weight: 700
    font-size: 31px
    line-height: 41.6px
.text-checkbox
    font-size: 16px
    font-weight: 400
    line-height: 20.8px
    text-align: center
    margin-top: 16px
</style>
